import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useApplicationState from "state/ApplicationState";

const AdminProtected = (props) => {
  const navigate = useNavigate();
  const state = useApplicationState();
  useEffect(() => {
    (async () => {
      const user = await state.fetchUser();
      if (!user || user.role !== "ADMIN") {
        console.log("UNAUTHORIZED USER, redirecting to login page");
        navigate("/login");
        return;
      }
    })();
  }, []);

  return (
    <>{state.user && state.user.role === "ADMIN" ? props.children : null}</>
  );
};

export default AdminProtected;
