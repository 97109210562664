import { useState } from "react";

export const useModal = () => {
  const [show, setShow] = useState(false);

  const closeModal = () => {
    setShow(false);
  };

  const isModalOpen = () => {
    return show;
  };

  const openModal = () => {
    setShow(true);
  };

  return {
    closeModal,
    isOpen: isModalOpen(),
    openModal,
  };
};

const Modal = ({ isOpen, children }) => {
  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="p-4 rounded shadow bg-[#FFDBA5] border-[#121212] border-[.5px] w-96">
        {children}
      </div>
    </div>
  );
};

export default Modal;
