import React, { useEffect, useState } from "react";
import useApplicationState from "state/ApplicationState";
import { Label } from "./BasicQuestion";

const OrderingQuestion = () => {
  const state = useApplicationState();
  const [qProps, setQProps] = useState(["ca1", "ca2", "ca3", "ca4"]);

  useEffect(() => {
    state.setQuestionData({
      ...state.questionData,
      answers: {
        ca1: "",
        ca2: "",
        ca3: "",
        ca4: "",
      },
    });
  }, []);

  return (
    <div className="relative flex flex-col w-full h-full gap-6 pt-4 overflow-y-scroll">
      <div className="sticky top-0 z-50 flex items-center self-end justify-center gap-2 border-2 border-black rounded-md cursor-pointer bg-itemBg w-fit right-2">
        <span
          className="material-symbols-outlined"
          onClick={() => {
            if (qProps.length < 6) {
              state.setQuestionData({
                ...state.questionData,
                answers: {
                  ...state.questionData.answers,
                  [qProps[qProps.length - 1]]: "",
                },
              });
              setQProps([...qProps, `ca${qProps.length + 1}`]);
            }
          }}
        >
          add
        </span>
        <span
          className="material-symbols-outlined"
          onClick={() => {
            if (qProps.length > 4) {
              state.setQuestionData({
                ...state.questionData,
                answers: {
                  ...state.questionData.answers,
                  [qProps[qProps.length - 1]]: undefined,
                },
              });
              setQProps(qProps.slice(0, qProps.length - 1));
            }
          }}
        >
          remove
        </span>
      </div>
      <Label title="Task" property="question" />
      <div className="flex flex-col gap-6 ordering">
        {qProps.map((prop, index) => {
          return <Label property={prop} title={index + 1} />;
        })}
      </div>
    </div>
  );
};

export default OrderingQuestion;
