import React, { useEffect } from "react";
import useApplicationState from "state/ApplicationState";

const BasicQuestion = () => {
  const state = useApplicationState();

  useEffect(() => {
    state.setQuestionData({
      ...state.questionData,
      answers: {},
    });
  }, []);

  return (
    <div className="flex flex-col w-full h-full gap-8 pt-4 overflow-y-scroll">
      <Label title="Question" property="question" />
      <Label title="Wrong answer 1" property="wa1" />
      <Label title="Wrong answer 2" property="wa2" />
      <Label title="Wrong answer 3" property="wa3" />
      <Label title="Correct answer" property="ca" />
    </div>
  );
};

export default BasicQuestion;

export const Label = (props) => {
  const state = useApplicationState();

  const { title, property, qb } = props;

  return (
    <div className="w-full h-[100px] min-h-[100px] flex justify-center items-center flex-col">
      {title ? (
        <div className="flex-[0.25] w-fit px-2 rounded-t-md self-start text-xl bg-itemBg border-t-2 border-l-2 border-r-2 border-black">
          {title}
        </div>
      ) : null}
      <div className="flex-[0.75] w-full bg-itemBg border-2 border-black p-2 relative">
        {qb ? (
          <div className="absolute flex gap-2 p-1 rounded-md -bottom-9 -left-1 bg-itemBg">
            <input
              onChange={(e) => {
                if (e.target.checked) {
                  state.setQuestionData({
                    ...state.questionData,
                    answers: {
                      ...state.questionData.answers,
                      [property]: {
                        ...state.questionData.answers[property],
                        correct: e.target.checked,
                      },
                    },
                  });
                }
              }}
              type="checkbox"
              name=""
              id=""
            />
            <label htmlFor="">This question is true</label>
          </div>
        ) : null}
        <div className="w-full h-full p-1 bg-white border-2 border-black">
          <textarea
            value={
              property === "question"
                ? state.questionData.question
                : (state.questionData.answers[property] &&
                    state.questionData.answers[property].text) ||
                  ""
            }
            onChange={(e) => {
              if (property === "question") {
                state.setQuestionData({
                  ...state.questionData,
                  question: e.target.value,
                });
              } else {
                state.setQuestionData({
                  ...state.questionData,
                  answers: {
                    ...state.questionData.answers,
                    [property]: {
                      ...state.questionData.answers[property],
                      text: e.target.value,
                    },
                  },
                });
              }
            }}
            className="w-full h-full outline-none resize-none"
          ></textarea>
        </div>
      </div>
    </div>
  );
};
