import React, { useEffect, useState } from "react";
import useApplicationState from "state/ApplicationState";
import { Label } from "./BasicQuestion";

const QuestionBombardment = () => {
  const state = useApplicationState();

  const [qProps, setQProps] = useState(["wa1", "wa2", "wa3", "wa4"]);

  useEffect(() => {
    state.setQuestionData({
      ...state.questionData,
      question: "QB",
      answers: {
        wa1: {
          text: "",
          correct: false,
        },
        wa2: {
          text: "",
          correct: false,
        },
        wa3: {
          text: "",
          correct: false,
        },
        wa4: {
          text: "",
          correct: false,
        },
      },
    });
  }, []);

  return (
    <div className="w-full h-full overflow-y-scroll flex flex-col gap-14 pt-4">
      <div className="bg-itemBg border-2 border-black rounded-md cursor-pointer w-fit self-end right-2 top-0 sticky flex gap-2 items-center justify-center z-50">
        <span
          className="material-symbols-outlined"
          onClick={() => {
            if (qProps.length < 6) {
              state.setQuestionData({
                ...state.questionData,
                answers: {
                  ...state.questionData.answers,
                  [`wa${qProps.length + 1}`]: {
                    text: "",
                    correct: false,
                  },
                },
              });
              setQProps([...qProps, `wa${qProps.length + 1}`]);
            }
          }}
        >
          add
        </span>
        <span
          className="material-symbols-outlined"
          onClick={() => {
            if (qProps.length > 4) {
              let newAnswers = { ...state.questionData.answers };
              delete newAnswers[qProps[qProps.length - 1]];
              state.setQuestionData({
                ...state.questionData,
                answers: newAnswers,
              });
              setQProps(qProps.slice(0, qProps.length - 1));
            }
          }}
        >
          remove
        </span>
      </div>
      {qProps.map((prop, index) => {
        return (
          <Label qb={true} title={`Question ${index + 1}`} property={prop} />
        );
      })}
    </div>
  );
};

export default QuestionBombardment;
