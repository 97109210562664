import React, { useEffect, useState } from "react";
import useApplicationState from "state/ApplicationState";
import { Label } from "./BasicQuestion";

const LinkingQuestion = () => {
  const state = useApplicationState();
  const [qProps, setQProps] = useState([
    "1a",
    "1b",
    "2a",
    "2b",
    "3a",
    "3b",
    "4a",
    "4b",
  ]);

  useEffect(() => {
    state.setQuestionData({
      ...state.questionData,
      answers: {
        "1a": {
          text: "",
        },
        "1b": {
          text: "",
        },
        "2a": {
          text: "",
        },
        "2b": {
          text: "",
        },
        "3a": {
          text: "",
        },
        "3b": {
          text: "",
        },
        "4a": {
          text: "",
        },
        "4b": {
          text: "",
        },
      },
    });
  }, []);

  return (
    <div className="w-full h-full overflow-y-scroll flex flex-col gap-6 pt-4">
      <div className="bg-itemBg border-2 border-black rounded-md cursor-pointer w-fit self-end right-2 top-0 sticky flex gap-2 items-center justify-center z-50">
        <span
          className="material-symbols-outlined"
          onClick={() => {
            if (qProps.length < 12) {
              state.setQuestionData({
                ...state.questionData,
                answers: {
                  ...state.questionData.answers,
                  [+qProps[qProps.length - 1][0] + 1 + "a"]: "",
                  [+qProps[qProps.length - 1][0] + 1 + "b"]: "",
                },
              });
              setQProps([
                ...qProps,
                `${+qProps[qProps.length - 1][0] + 1}a`,
                `${+qProps[qProps.length - 1][0] + 1}b`,
              ]);
            }
          }}
        >
          add
        </span>
        <span
          className="material-symbols-outlined"
          onClick={() => {
            if (qProps.length > 8) {
              state.setQuestionData({
                ...state.questionData,
                answers: {
                  ...state.questionData.answers,
                  [qProps[qProps.length - 1]]: undefined,
                },
              });

              const questionCopy = state.questionDataCopy();

              delete questionCopy.answers[+qProps[qProps.length - 1][0] + "a"];
              delete questionCopy.answers[+qProps[qProps.length - 1][0] + "b"];
              console.log(questionCopy);
              state.setQuestionData(questionCopy);

              setQProps(qProps.slice(0, qProps.length - 2));
            }
          }}
        >
          remove
        </span>
      </div>
      <Label title="Task" property="question" />
      {qProps.map((prop) => {
        return <Label property={prop} title={prop} key={prop} />;
      })}
    </div>
  );
};

export default LinkingQuestion;
