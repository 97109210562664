import axios from "axios";
import { CONFIG } from "config/ApplicationConfig";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const Leaderboard = () => {
  const columns = [
    "Rank",
    "Player",
    "Multiplier",
    "Votes Cast",
    "Submitted Qs",
    "Accepted Qs",
    "Credit",
  ];

  const [data, setData] = useState({
    leaderboard: [],
    self: [],
  });

  useEffect(() => {
    (async () => {
      const response = await axios.get(`${CONFIG.API_URL}/web/leaderboard`, {
        withCredentials: true,
      });

      console.log(response.data);
      setData(response.data);
    })();
  }, []);

  return (
    <div className="flex items-center justify-center h-full rounded-md w-max md:w-full bg-itemBg">
      <header className="overflow-scroll md:overflow-hidden App-header ">
        <Table columns={columns} data={data} />
      </header>
    </div>
  );
};

export default Leaderboard;

const Table = ({ columns, data }) => {
  return (
    <div className="overflow-auto w-full bg-mainBg p-4 rounded-md min-h-[500px] border-shadow">
      <div className="flex justify-between">
        <NavLink
          to={"/home"}
          className="flex items-center justify-center py-1 pl-2 mb-2 border-[.5px] border-black rounded-md cursor-pointer bg-itemBg w-fit"
        >
          <span className="material-symbols-outlined">arrow_back_ios</span>
        </NavLink>
        <div className="text-3xl text-itemBg">Leaderboard</div>
        <div className=""></div>
      </div>
      <div className="flex flex-col w-full">
        <div className="sticky top-0 z-10 flex items-center gap-2 mx-1">
          {columns.map((column, index) => (
            <div
              key={index}
              className="flex-1 w-32 text-xl font-medium text-center text-black"
              style={{
                flex: "1",
                backgroundColor: "#FFDBA5",
                borderRadius: "4px",
                border: "1px solid #000000",
              }}
            >
              {column}
            </div>
          ))}
        </div>
        <div className="h-2"></div>
        <div className="flex flex-col gap-2 divide-y max-h-[500px] overflow-y-auto scrollbar-leaderboard">
          {/* TODO: Sajat statok */}
          <div className="flex gap-2 items-center bg-[#ffc877] border-[#121212] border-[.5px] rounded p-1">
            {data &&
              data.self.map((_, index) => (
                <div
                  className="flex-1 py-1 whitespace-nowrap"
                  style={{
                    backgroundColor: "#FFB46E",
                    boxShadow: "inset 5px 7px 14px rgba(0, 0, 0, 0.25)",
                    borderRadius: "4px",
                  }}
                >
                  <div className="px-2 text-xl text-black">{_}</div>
                </div>
              ))}
          </div>
          {data &&
            data.leaderboard.map((row, rowIndex) => (
              <div
                key={rowIndex}
                className="flex gap-2 items-center bg-[#FFDBA5] border-[#121212] border-[.5px] rounded p-1"
              >
                {row.map((cell, cellIndex) => (
                  <div
                    key={cellIndex}
                    className="flex-1 py-1 whitespace-nowrap"
                    style={{
                      backgroundColor: "#FFCEA0",
                      boxShadow: "inset 5px 7px 14px rgba(0, 0, 0, 0.25)",
                      borderRadius: "4px",
                    }}
                    title={
                      cellIndex === 1 && cell.length > 12 ? cell : undefined
                    }
                  >
                    <div className="px-2 text-xl text-black">
                      {cellIndex === 1 && cell.length > 12
                        ? `${cell.slice(0, 9)}...`
                        : cell}
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
