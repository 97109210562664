import axios from "axios";
import { CONFIG } from "config/ApplicationConfig";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import useApplicationState from "state/ApplicationState";

const AdminInterface = () => {
  const [config, setConfig] = useState(null);
  const [availableQuestions, setAvailableQuestions] = useState({
    availableCategories: {},
    availableSubCategories: {},
  });
  const state = useApplicationState();

  useEffect(() => {
    (async () => {
      const config = await axios.get(`${CONFIG.API_URL}/web/config`, {
        withCredentials: true,
      });

      if (config.data.config) {
        setConfig(config.data.config);
      }
    })();
  }, []);

  useEffect(() => {
    if (state.questionConfig) {
      setAvailableQuestions({
        availableCategories: state.questionConfig.availableCategories,
        availableSubCategories: state.questionConfig.availableSubCategories,
      });
    }
  }, [state.questionConfig]);

  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="bg-mainBg border-shadow h-2/3 flex-col w-1/2 flex justify-center items-center rounded-md py-4">
        <div className="bg-itemBg w-[95%] h-full flex-[0.9] border-2 border-black overflow-y-scroll gap-2 flex flex-col p-4">
          {config
            ? Object.keys(config).map((property) => {
                return (
                  <ConfigInput
                    property={property}
                    value={config[property]}
                    isId={property.includes("Id")}
                    setConfig={setConfig}
                  />
                );
              })
            : null}
          <div className="border-separate w-full py-2 h-1"></div>
          <h1 className="p-4 font-bold">AVAILABLE QUESTION TYPES</h1>
          <h1 className="p-2">Categories</h1>

          {state.questionConfig
            ? Object.entries(availableQuestions.availableCategories).map(
                ([key, value]) => {
                  return (
                    <CategoryInput
                      property={key}
                      value={value}
                      setConfig={setAvailableQuestions}
                      keyName="availableCategories"
                    />
                  );
                }
              )
            : null}
          <h1 className="p-2 pt-8">Subcategories</h1>
          {state.questionConfig
            ? Object.entries(availableQuestions.availableSubCategories).map(
                ([key, value]) => {
                  return (
                    <CategoryInput
                      property={key}
                      value={value}
                      setConfig={setAvailableQuestions}
                      keyName="availableSubCategories"
                    />
                  );
                }
              )
            : null}
        </div>
        <div className="flex flex-[0.1] w-full justify-between px-6 mt-2 items-center">
          <NavLink
            to={"/home"}
            className="flex items-center justify-center py-1 pl-2 mb-2 border-[.5px] border-black rounded-md cursor-pointer bg-itemBg w-fit"
          >
            <span className="material-symbols-outlined">arrow_back_ios</span>
          </NavLink>
          <div
            className="flex justify-center items-center cursor-pointer"
            onClick={async () => {
              await axios.post(
                `${CONFIG.API_URL}/web/change-config`,
                {
                  config: config,
                },
                {
                  withCredentials: true,
                }
              );

              await axios.post(
                `${CONFIG.API_URL}/web/change-available-questions`,
                {
                  availableCategories: availableQuestions.availableCategories,
                  availableSubCategories:
                    availableQuestions.availableSubCategories,
                },
                {
                  withCredentials: true,
                }
              );
            }}
          >
            <p className="bg-itemBg border-shadow text-3xl p-1">Confirm</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminInterface;

const ConfigInput = (props) => {
  const { property, value, setConfig, isId } = props;

  return (
    <div className="text-xl flex items-center">
      <div className="flex-[0.7]">
        <p className="text-xl">{property}</p>
      </div>
      <div className="flex-[0.3] border-[1px] border-black px-2 rounded-md">
        <input
          className="h-full w-full bg-transparent outline-none"
          type="text"
          value={value}
          onInput={(e) => {
            setConfig((config) => {
              return {
                ...config,
                //[property]: isId ? e.target.value : +e.target.value,
                [property]: e.target.value,
              };
            });
          }}
        />
      </div>
    </div>
  );
};

const CategoryInput = (props) => {
  const { property, value, setConfig, keyName } = props;

  return (
    <div className="text-xl flex items-center">
      <div className="flex-[0.7]">
        <p className="text-xl">{property}</p>
      </div>
      <div className="flex-[0.3] border-[1px] border-black px-2 rounded-md">
        <input
          className="h-full w-full bg-transparent outline-none"
          type="checkbox"
          checked={value} // Initialize checkbox based on the value
          onChange={(e) => {
            setConfig((config) => {
              return {
                ...config,
                [keyName]: {
                  ...config[keyName],
                  [property]: e.target.checked,
                },
              };
            });
          }}
        />
      </div>
    </div>
  );
};
