import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useApplicationState from "state/ApplicationState";

const QuestionDescriptionPage = (props) => {
  const { type } = props;
  const applicationState = useApplicationState();
  const [currentCategory, setCurrentCategory] = useState(
    "Blockchain and Cryptocurrency"
  );
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (!type) {
      navigate("/question-type");
    }
  }, []);

  const subCategories = [
    "Blockchain and Cryptocurrency",
    "Trading",
    "Economics",
  ];

  const state = useApplicationState();

  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="flex items-center justify-center w-full p-8 m-5 lg:w-1/2 bg-mainBg h-3/4 border-shadow">
        <div className="flex flex-col w-full h-full rounded-md bg-itemBg">
          <div className="flex flex-[0.05] border-2 border-black rounded-t-md">
            {state.questionConfig
              ? subCategories.map((subCategory) =>
                  state.questionConfig.availableSubCategories[subCategory] ? (
                    <div
                      key={subCategory}
                      className={`${
                        currentCategory === subCategory ? "bg-activeBg" : ""
                      } cursor-pointer flex w-full h-full justify-center items-center text-xl text-center ${
                        subCategory === "Trading"
                          ? "border-l-2 border-r-2 border-black"
                          : ""
                      }`}
                      onClick={() => setCurrentCategory(subCategory)}
                    >
                      {subCategory}
                    </div>
                  ) : null
                )
              : null}
          </div>
          <div className="flex flex-[0.05] bg-yellow-50 border-l-2 border-r-2 border-black">
            <div className="flex items-center w-full h-full px-2 search__bar">
              <div className="search__input flex-[1] flex items-center h-full w-full gap-2">
                <span className="text-lg material-symbols-outlined">
                  search
                </span>
                <input
                  className="w-full h-full bg-transparent outline-none"
                  type={"text"}
                  onInput={(e) => {
                    setSearch(e.target.value);
                  }}
                  value={search}
                />
              </div>
              <div className="search__expand flex-[0.05] flex justify-center items-center w-full h-full">
                <span className="material-symbols-outlined">expand_more</span>
              </div>
            </div>
          </div>
          <div className="flex flex-[0.7] bg-yellow-50 border-2 border-black overflow-y-scroll">
            <div className="flex flex-col w-full h-full px-2">
              {categories[currentCategory]
                .filter((category) =>
                  category.toLowerCase().includes(search.toLowerCase())
                )
                .map((category, index) => {
                  return (
                    <p
                      key={category}
                      className={`font-sans cursor-pointer ${
                        applicationState.questionData.subCategory === category
                          ? "text-activeBg"
                          : "font-normal"
                      }`}
                      onClick={() => {
                        let currentData = applicationState.questionDataCopy();
                        currentData.subCategory = category;
                        applicationState.setQuestionData(currentData);
                      }}
                    >
                      {`${index + 1}. ${category}`}
                    </p>
                  );
                })}
            </div>
          </div>
          <div className="flex flex-[0.2] bg-mainBg">
            <div className="flex items-center justify-between w-full h-full">
              <NavLink
                to={"/question-type"}
                className="flex items-center justify-center py-1 pl-2 border-2 border-black rounded-md cursor-pointer bg-itemBg"
              >
                <span className="material-symbols-outlined">
                  arrow_back_ios
                </span>
              </NavLink>
              <div className="px-6 border-2 border-black cursor-pointer rounded-3xl bg-itemBg">
                <p
                  className="text-2xl"
                  onClick={() => {
                    let currentData = applicationState.questionDataCopy();
                    currentData.category = currentCategory;
                    currentData.type = type;
                    applicationState.setQuestionData(currentData);
                    navigate("/question-answers");
                  }}
                >
                  Next
                </p>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionDescriptionPage;

const categories = {
  "Blockchain and Cryptocurrency": [
    "Cryptocurrency Basics",
    "Blockchain Technology",
    "Altcoins",
    "Cryptocurrency Exchanges",
    "Wallets and Storage",
    "ICOs and Token Sales",
    "Decentralized Finance (DeFi)",
    "NFTs (Non-Fungible Tokens)",
    "Cryptocurrency Regulations",
    "Security and Privacy",
    "Cryptocurrency Mining",
    "Stablecoins",
    "Layer 2 Solutions and Scaling",
    "Smart Contracts",
    "Cryptocurrency Use Cases and Adoption",
    "Cryptocurrency History and Milestones",
    "Influential Figures in the Cryptocurrency Space",
    "Cryptocurrency Taxation",
    "Central Bank Digital Currencies (CBDCs)",
    "Crypto Lending Platforms",
    "Cryptocurrency Staking and Yield Farming",
    "Cross-Chain Technologies",
    "Privacy Coins",
    "Cryptocurrency Forks",
    "Cryptocurrency Market Analysis",
    "Blockchain Consensus Mechanisms",
    "Cryptocurrency Airdrops and Swaps",
    "Social Impact of Cryptocurrencies",
    "Tokenomics and Token Models",
    "Crypto-backed Loans",
    "Decentralized Exchanges (DEXs)",
    "Crypto Wallet Types",
    "Blockchain Interoperability",
    "Cryptocurrency Trading Bots",
    "Blockchain Gaming and Virtual Worlds",
    "Decentralized Autonomous Organizations (DAOs)",
    "Cryptocurrency Community and Culture",
    "On-chain Analytics",
    "Crypto Payment Solutions",
    "Blockchain and IoT (Internet of Things)",
    "Blockchain and AI (Artificial Intelligence)",
    "Sidechains and Off-chain Solutions",
    "Cryptocurrency Derivatives",
    "Decentralized Identity Solutions",
    "Oracle Services",
    "Blockchain Development Platforms",
    "Asset Tokenization",
    "Decentralized Cloud Storage",
    "Crypto Ecosystem Infrastructure",
    "Security Token Offerings (STOs)",
    "Crypto Insurance",
    "Blockchain and Supply Chain Management",
    "Crypto Philanthropy and Grants",
    "Blockchain in Healthcare",
    "Blockchain in Education",
    "Blockchain in Real Estate",
    "Domain-specific Blockchains",
  ],
  Trading: [
    "Technical Analysis",
    "Fundamental Analysis",
    "Sentiment Analysis",
    "Risk Management",
    "Trading Psychology",
    "Trading Strategies",
    "Chart Patterns",
    "Indicators and Oscillators",
    "Timeframes",
    "Order Types",
    "Trading Platforms and Tools",
    "Cryptocurrency Exchanges",
    "Margin Trading",
    "Leverage and Liquidation",
    "Market Structure",
    "Market Cycles",
    "Portfolio Management",
    "Asset Allocation",
    "Diversification",
    "Trading Pairs",
    "Trading Bots and Algorithmic Trading",
    "Arbitrage Trading",
    "Day Trading",
    "Swing Trading",
    "Scalping",
    "Position Trading",
    "Trend Trading",
    "Breakout Trading",
    "Support and Resistance Levels",
    "Fibonacci Retracements and Extensions",
    "Moving Averages",
    "RSI (Relative Strength Index)",
    "MACD (Moving Average Convergence Divergence)",
    "Ichimoku Cloud",
    "Bollinger Bands",
    "Elliot Wave Theory",
    "Candlestick Patterns",
    "Trading Journals and Record Keeping",
    "Tax Implications of Trading",
    "Trading Regulations and Compliance",
  ],
  Economics: [
    "Supply and Demand",
    "Inflation",
    "Interest Rates",
    "Opportunity Cost",
    "Money Supply",
    "Quantitative Easing",
    "Inflation Targeting",
    "Stock Market Indices",
    "Commodities",
    "Bonds",
    "Derivatives",
    "Foreign Exchange Market",
    "Currency Pairs",
    "Exchange Rate Systems (Floating, Fixed, Pegged)",
    "Government Spending",
    "Taxation",
    "Budget Deficits and Surpluses",
    "Public Debt",
    "Gross Domestic Product (GDP)",
    "Unemployment Rate",
    "Consumer Price Index (CPI)",
    "Producer Price Index (PPI)",
    "Balance of Trade",
    "Trade Agreements",
    "Import and Export",
    "Protectionism",
    "Perfect Competition",
    "Monopoly",
    "Oligopoly",
    "Monopolistic Competition",
    "Capitalism",
    "Socialism",
    "Mixed Economy",
    "Communism",
    "Economic Growth Theories",
    "Human Development Index (HDI)",
    "Income Inequality",
    "Sustainable Development Goals (SDGs)",
    "Labor Force Participation Rate",
    "Types of Unemployment",
    "Minimum Wage",
    "Labor Unions",
    "Social Security",
    "Healthcare",
    "Education",
    "Public Infrastructure",
    "Saving and Investing",
    "Retirement Planning",
    "Credit and Loans",
    "Insurance",
  ],
};
