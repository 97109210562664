import { CONFIG } from "config/ApplicationConfig";
import React from "react";
import axios from "axios";

const LoginPage = () => {
  return (
    <>
      <div className="flex items-center justify-center h-full">
        <div className="Login__Wrapper w-[256px] h-56 flex justify-center flex-col gap-4 bg-[#e5b485] border-shadow p-6">
          <p className="text-xl text-center text-black">
            Welcome on board! To use the application, you have to login using
            your Discord!
          </p>
          <a
            className="p-2 text-xl text-center transition-all rounded-md bg-itemBg hover:bg-opacity-90 duration-400"
            href={`${CONFIG.API_URL}/discord/oauth`}
          >
            Login via Discord
          </a>
        </div>
      </div>
    </>
  );
};
export default LoginPage;
