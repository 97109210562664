import React, { useEffect } from "react";
import useApplicationState from "state/ApplicationState";
import { Label } from "./BasicQuestion";

const CalculationQuestion = () => {
  const state = useApplicationState();

  useEffect(() => {
    state.setQuestionData({
      ...state.questionData,
      answers: {},
    });
  }, []);

  return (
    <div className="w-full h-full overflow-y-scroll flex flex-col gap-8 pt-4">
      <Label title="Question" property="question" />
      <Label title="Correct answer" property="ca" />
    </div>
  );
};

export default CalculationQuestion;
