import axios from "axios";
import { CONFIG } from "config/ApplicationConfig";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useApplicationState from "state/ApplicationState";

const QuestionSubmit = () => {
  const state = useApplicationState();
  const navigate = useNavigate();

  useEffect(() => {

  }, []);

  return (
    <div className="text-2xl font-serif">
      {JSON.stringify(state.questionData)}
    </div>
  );
};

export default QuestionSubmit;
