import axios from "axios";
import Modal, { useModal } from "components/modal";
import { CONFIG } from "config/ApplicationConfig";
import BasicQuestion from "question-types/BasicQuestion";
import CalculationQuestion from "question-types/CalculationQuestion";
import LinkingQuestion from "question-types/LinkingQuestion";
import OrderingQuestion from "question-types/OrderingQuestion";
import QuestionBombardment from "question-types/QuestionBombardment";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useApplicationState from "state/ApplicationState";

const QuestionAnswersPage = () => {
  const state = useApplicationState();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !state.questionData.type ||
      !state.questionData.category ||
      !state.questionData.subCategory
    ) {
      navigate("/question-type");
      return;
    }
  }, []);

  const { openModal, isOpen, closeModal } = useModal();
  const [modalContent, setModalContent] = useState(null);

  const currentCategory = state.questionData.category;

  const getQuestionComponent = () => {
    switch (state.questionData.type) {
      case "Basic":
        return <BasicQuestion />;
      case "Calculation":
        return <CalculationQuestion />;
      case "Ordering":
        return <OrderingQuestion />;
      case "Linking":
        return <LinkingQuestion />;
      case "Question Bombardment":
        return <QuestionBombardment />;
      default:
        return <div>Question type not found</div>;
    }
  };

  const getTooltip = (type) => {
    switch (type) {
      case "Basic":
        return "You are required to enter one correct and three wrong answers.";
      case "Calculation":
        return "Please input a question that requires a numerical answer.";
      case "Ordering":
        return "You are required to enter at least 4 statements and list the statements in the correct order.";
      case "Linking":
        return "You are required to enter at least 4 statements. The first half of the statement is 'A' and the second half is 'B'. Based on this, please list the correct solutions.";
      case "Question Bombardment":
        return "You are required to enter at least 4 statements. Answer can be yes or no.";
      default:
        return "Question type not found";
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="flex items-center justify-center w-[95%] h-auto p-8 lg:w-3/4 xl:w-1/2 bg-mainBg border-shadow">
        <div className="flex flex-col w-full h-full rounded-md bg-mainBg">
          <div className="flex flex-[0.05] border-2 border-black rounded-t-md bg-itemBg">
            <div
              className={`${
                currentCategory === "Blockchain and Cryptocurrency"
                  ? "bg-activeBg"
                  : ""
              } flex w-full h-full justify-center items-center text-xl text-center`}
            >
              Blockchain and Cryptocurrency
            </div>
            <div
              className={`${
                currentCategory === "Trading" ? "bg-activeBg" : ""
              } flex w-full h-full justify-center items-center border-l-2 border-r-2 border-black text-xl text-center`}
            >
              Trading
            </div>
            <div
              className={`${
                currentCategory === "Economics" ? "bg-activeBg" : ""
              }flex w-full h-full justify-center items-center text-xl text-center`}
            >
              Economics
            </div>
          </div>
          <div className="flex items-center w-full px-2 border-b-2 border-l-2 border-r-2 border-black bg-itemBg search__bar rounded-b-md">
            <div className="search__input flex-[1] flex items-center h-full w-full gap-2">
              <span class="material-symbols-outlined text-lg">search</span>
              <p className="w-full h-full outline-none">
                {state.questionData.subCategory}
              </p>
            </div>
          </div>

          <div className="w-full p-1 mt-4 bg-white border-2 border-black rounded-md max-h-fit min-h-12">
            <p className="text-xl">{getTooltip(state.questionData.type)}</p>
          </div>
          <div className="flex flex-[0.85] max-h-[450px] w-full">
            {getQuestionComponent()}
          </div>
          <div className="flex flex-[0.2] bg-mainBg pt-5">
            <div className="flex items-center justify-between w-full h-full">
              <NavLink
                to={"/question-category"}
                className="flex items-center justify-center py-1 pl-2 border-2 border-black rounded-md cursor-pointer bg-itemBg"
              >
                <span class="material-symbols-outlined">arrow_back_ios</span>
              </NavLink>
              <div className="px-6 border-2 border-black cursor-pointer rounded-3xl bg-itemBg">
                <p
                  className="text-2xl"
                  onClick={() => {
                    // Add saving state
                    (async () => {
                      if (
                        state.questionData.type === null ||
                        state.questionData.category === null ||
                        state.questionData.subCategory === null ||
                        state.questionData.question === null ||
                        state.questionData.answers === null
                      ) {
                        navigate("/");
                        return;
                      }

                      try {
                        const response = await axios.post(
                          `${CONFIG.API_URL}/web/question/submit`,
                          {
                            questionData: state.questionData,
                          },
                          {
                            withCredentials: true,
                          }
                        );

                        setModalContent("Question submitted successfully!");
                        console.log(
                          "Question data has been reset successfully."
                        );
                      } catch (err) {
                        console.log(`Error submitting question: ${err}`);
                        setModalContent(
                          `An error occurred while saving the question. Please try again!`
                        );
                      }
                      openModal();
                    })();
                  }}
                >
                  Submit for review
                </p>
              </div>
              <Modal isOpen={isOpen} closeModal={closeModal}>
                <h2 className="mb-4 text-xl">Question submit</h2>
                <p className="mb-4">{modalContent}</p>
                <button
                  className="px-6 border-[.5px] border-black rounded-md cursor-pointer bg-itemBg"
                  onClick={() => {
                    closeModal();
                    if (modalContent === "Question submitted successfully!") {
                      navigate("/");
                      state.resetQuestionData();
                    }
                  }}
                >
                  Close
                </button>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionAnswersPage;
