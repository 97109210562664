import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useApplicationState from "state/ApplicationState";

const AuthProtected = (props) => {
  const navigate = useNavigate();
  const state = useApplicationState();
  useEffect(() => {
    (async () => {
      const user = await state.fetchUser();
      if (!user) {
        console.log("User not logged in, redirecting to login page");
        navigate("/login");
        return;
      }
    })();
  }, []);

  return <>{state.user ? props.children : null}</>;
};

export default AuthProtected;
