import AdminProtected from "components/AdminProtected";
import AuthProtected from "components/AuthProtected";
import AdminInterface from "pages/AdminInterface";
import Leaderboard from "pages/Leaderboard";
import LoginPage from "pages/LoginPage";
import QuestionAnswersPage from "pages/QuestionAnswersPage";
import QuestionDescriptionPage from "pages/QuestionDescriptionPage";
import QuestionSelectionPage from "pages/QuestionSelectionPage";
import QuestionSubmit from "pages/QuestionSubmit";
import UserSelectionPage from "pages/UserSelectionPage";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import useApplicationState from "state/ApplicationState";
import "./App.css";
import Modal, { useModal } from "components/modal";

const App = () => {
  const state = useApplicationState();

  const { closeModal, isOpen, openModal } = useModal();

  useEffect(() => {
    (async () => {
      await state.fetchUser();
      await state.fetchAvailableQuestionTypes();
    })();
    const isFirefox = /Firefox/i.test(navigator.userAgent);
    const firefoxNoticeAccepted = localStorage.getItem("firefoxNoticeAccepted");

    if (isFirefox && firefoxNoticeAccepted !== "true") {
      openModal();
    }
  }, []);

  return (
    <>
      <div className="w-screen h-screen App bg-itemBg">
        <Routes>
          <Route
            path="/"
            element={
              <AuthProtected>
                <UserSelectionPage />
              </AuthProtected>
            }
          />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/home"
            element={
              <AuthProtected>
                <UserSelectionPage />
              </AuthProtected>
            }
          />
          <Route
            path="/question-type"
            element={
              <AuthProtected>
                <QuestionSelectionPage />
              </AuthProtected>
            }
          />
          <Route
            path="/question-category"
            element={
              <AuthProtected>
                <QuestionDescriptionPage type={state.questionData.type} />
              </AuthProtected>
            }
          />
          <Route
            path="/question-answers"
            element={
              <AuthProtected>
                <QuestionAnswersPage />
              </AuthProtected>
            }
          />
          <Route
            path="/question-submit"
            element={
              <AuthProtected>
                <QuestionSubmit />
              </AuthProtected>
            }
          />
          <Route
            path="/leaderboard"
            element={
              <AuthProtected>
                <Leaderboard />
              </AuthProtected>
            }
          />
          {state && state.user && state.user.role === "ADMIN" ? (
            <Route
              path="/admin"
              element={
                <AdminProtected>
                  <AdminInterface />
                </AdminProtected>
              }
            />
          ) : null}
          <Route path="*" element={<div>404</div>} />
        </Routes>
      </div>
      <Modal isOpen={isOpen} closeModal={closeModal}>
        <h2 className="mb-4 text-xl">Important Notice for Firefox Users</h2>
        <p className="mb-4">
          To ensure the best browsing experience on our website, it is important
          to allow cookies. Unfortunately, Firefox may require you to approve
          cookies manually.
        </p>
        <button
          className="px-6 border-[.5px] border-black rounded-md cursor-pointer bg-itemBg"
          onClick={() => {
            localStorage.setItem("firefoxNoticeAccepted", "true");
            closeModal();
          }}
        >
          Close
        </button>
      </Modal>
    </>
  );
};

export default App;
